<template>
  <div class="gameWarp">
    <AdvSwiper
      :advList="advList"
      v-if="advList.length"
      class="adv"
      height="111px"
    />
    <van-notice-bar
      :text="marqueeText"
      background="#ff7d9f"
      color="#ffffff"
      speed="40"
      v-if="marqueeText"
    >
      <template slot="left-icon">
        <div class="left_icon">
          <svg-icon iconClass="notice"></svg-icon>
          <span>公告：</span>
        </div>
      </template>
    </van-notice-bar>
    <div class="mainContent">
      <div class="balanceBox">
        <div class="balanceTitle">游戏余额 (元)</div>
        <div class="balance">
          <svg-icon class="gameGold" iconClass="game_gold"></svg-icon>
          <div class="amount">{{ balance }}</div>
        </div>
        <router-link tag="div" to="/billDetails?t=game" class="bill">
          我的账单
          <svg-icon class="rightArrow" iconClass="rightArrow"></svg-icon>
        </router-link>
      </div>
      <div class="chooseBox">
        <router-link tag="div" to="/gameWallet" class="chooseItem">
          <svg-icon class="chooseIcon" iconClass="game_gold"></svg-icon>
          <div>充值</div>
        </router-link>
        <router-link tag="div" to="/gameWithdraw" class="chooseItem">
          <svg-icon
            class="chooseIcon chooseIconNotTip"
            iconClass="game_wallet"
          ></svg-icon>
          <div>提现</div>
        </router-link>
        <!-- <div class="chooseItem" @click="toBindPhone">
                    <svg-icon class="chooseIcon" iconClass="game_phone"></svg-icon>
                    <div class="chooseText">绑手机</div>
                </div> -->
        <router-link tag="div" to="/incomeCenter" class="chooseItem">
          <svg-icon
            class="chooseIcon chooseIconNotTip"
            iconClass="game_agent"
          ></svg-icon>
          <div>全民代理</div>
        </router-link>
        <router-link tag="div" to="/gameRules" class="chooseItem">
          <svg-icon
            class="chooseIcon chooseIconNotTip"
            iconClass="gameRules"
          ></svg-icon>
          <div>活动</div>
        </router-link>
      </div>
      <div class="last-play" v-show="playedGame.length > 0">
        <p class="play-words">最近玩过</p>
        <div class="game-list">
          <div
            class="list-item"
            v-for="item in playedGame"
            :key="item.id"
            @click="playgame(item)"
          >
            <img :src="item.img" alt="" />
            <p class="game-title">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="gameTitle">全部游戏</div>
      <div class="allgame-list">
        <div
          class="allgame-item"
          v-for="item in gameList"
          :key="item.id"
          @click="playgame(item)"
        >
          <img :src="item.img" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gamePMD, gameBalance } from '@/api/game';
import { NoticeBar } from 'vant';
import { Toast } from 'vant';
import { AdType, getAdItem } from '@/utils/getConfig';
import AdvSwiper from '@/components/AdvSwiper';
export default {
  nema: 'gamePage',
  components: {
    AdvSwiper,
    [NoticeBar.name]: NoticeBar,
  },
  data() {
    return {
      balance: 0,
      marqueeText: '',
      advList: [],
      playedGame: [],
      gameList: [
        {
          id: 1,
          img: require('@/assets/gameimg/py.png'),
          name: '捕鱼',
        },
        {
          id: 2,
          img: require('@/assets/gameimg/ddz.png'),
          name: '斗地主',
        },
        {
          id: 3,
          img: require('@/assets/gameimg/zjh.png'),
          name: '炸金花',
        },
        {
          id: 4,
          img: require('@/assets/gameimg/brnn.png'),
          name: '百人牛牛',
        },
        {
          id: 5,
          img: require('@/assets/gameimg/qznn.png'),
          name: '抢庄牛牛',
        },
        {
          id: 6,
          img: require('@/assets/gameimg/ermj.png'),
          name: '二人麻将',
        },
        {
          id: 7,
          img: require('@/assets/gameimg/hhdz.png'),
          name: '红黑大战',
        },
        {
          id: 8,
          img: require('@/assets/gameimg/dzpk.png'),
          name: '德州扑克',
        },
        {
          id: 10,
          img: require('@/assets/gameimg/pdk.png'),
          name: '跑得快',
        },
        {
          id: 12,
          img: require('@/assets/gameimg/lhdz.png'),
          name: '龙虎斗',
        },
        {
          id: 18,
          img: require('@/assets/gameimg/bcbm.png'),
          name: '奔驰宝马',
        },
        {
          id: 19,
          img: require('@/assets/gameimg/fqzs.png'),
          name: '飞禽走兽',
        },
        {
          id: 25,
          img: require('@/assets/gameimg/hjk.png'),
          name: '黑杰克',
        },
        {
          id: 26,
          img: require('@/assets/gameimg/qzsg.png'),
          name: '抢庄三公',
        },
        {
          id: 28,
          img: require('@/assets/gameimg/qzpj.png'),
          name: '抢庄牌九',
        },
        {
          id: 29,
          img: require('@/assets/gameimg/ebg.png'),
          name: '二八杠',
        },
        {
          id: 30,
          img: require('@/assets/gameimg/dfdc.png'),
          name: '多福多财',
        },
        {
          id: 31,
          img: require('@/assets/gameimg/hbsl.png'),
          name: '红包扫雷',
        },
        {
          id: 32,
          img: require('@/assets/gameimg/jdnn.png'),
          name: '经典牛牛',
        },
      ],
    };
  },
  created() {
    this.playedGame = localStorage.getItem('playedGame');
    if (!this.playedGame) {
      localStorage.setItem('playedGame', JSON.stringify([]));
      this.playedGame = [];
    } else {
      this.playedGame = JSON.parse(this.playedGame);
    }
    this.advList = getAdItem(AdType.GAME_CENTER);
    this.queryAnnouncement();
    this.queryGameBalance();
  },
  methods: {
    playgame(item) {
      let L = this.playedGame.length;
      for (let i = 0; i < L; i++) {
        if (this.playedGame[i].id == item.id) {
          this.playedGame.splice(i, 1);
          break;
        }
      }
      this.playedGame.unshift({
        id: item.id,
        img: item.img,
        name: item.name,
      });
      localStorage.setItem('playedGame', JSON.stringify(this.playedGame));
      this.$router.push({
        path: '/gameplay',
        query: {
          id: item.id,
        },
      });
    },
    // 获取游戏公告
    async queryAnnouncement() {
      let ret = await this.$Api(gamePMD);
      if (ret && ret.code == 200) {
        ret.data.announcement.forEach((i) => {
          this.marqueeText += `${i}                `;
        });
      }
    },
    // 获取游戏余额
    async queryGameBalance() {
      let ret = await this.$Api(gameBalance);
      if (ret && ret.code == 200) {
        this.balance = ret.data.wlBalance || 0;
      }
    },
    // 绑定手机
    toBindPhone() {
      let uInfo = this.$store.getters.userInfo;
      if (uInfo.mobile) {
        Toast('您已绑定，不能重复绑定');
        return;
      }
      this.$router.push('/bindPhone');
    },
  },
};
</script>

<style lang="scss" scoped>
.gameWarp {
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  // white-space: pre-wrap;
  -webkit-overflow-scrolling: touch;

  .title {
    height: 41px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mainContent {
    padding: 22px 16px 0;
    box-sizing: border-box;

    .balanceBox {
      height: 100px;
      border-radius: 10px;
      background: linear-gradient(to right, #a770ef, #cf8bf3, #fdaa9b);
      padding: 10px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .balanceTitle {
        font-size: 12.5px;
      }

      .balance {
        display: flex;
        align-items: center;

        .gameGold {
          width: 31px;
          height: 31px;
          margin-right: 7px;
        }

        .amount {
          font-size: 27px;
          // font-weight: 600;
        }
      }

      .bill {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;

        .rightArrow {
          font-size: 12px;
        }
      }
    }

    .chooseBox {
      margin: 20px 0;
      padding: 12px 0;
      height: 67.5px;
      width: 100%;
      border-radius: 6px;
      display: flex;
      background: #f2f5f8;
      align-items: center;
      justify-content: space-around;

      .chooseItem {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .chooseText {
          width: 100%;
        }

        .rechargeText {
          padding-left: 10px;
        }

        .chooseIcon {
          font-size: 54px;
        }

        .chooseIconNotTip {
          max-width: 41px;
        }
      }
    }

    .last-play {
      margin-bottom: 10px;
      .play-words {
        font-size: 18px;
        margin-bottom: 11.5px;
      }
      .game-list {
        width: 100%;
        display: flex;
        text-align: center;
        overflow-x: auto;
        .list-item {
          margin-right: 16px;
          img {
            width: 100%;
            height: 128px;
            border-radius: 6px;
            object-fit: fill;
          }
          .game-title {
            font-size: 14px;
          }
        }
      }
    }

    .gameTitle {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .allgame-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      .allgame-item {
        // width: 195px;
        // height: 107px;
        text-align: center;
        font-size: 16px;

        img {
          width: 100%;
          height: 128px;
          border-radius: 8px;
          object-fit: fill;
          margin-bottom: 6px;
        }
      }
    }
  }
  .adv {
  }
}
</style>
